import React, {useRef, useState, useEffect} from 'react';
import clsx from 'clsx'
import {ReactComponent as LeftArrow} from '../../../assets/icons/chevron-left.svg'
import {ReactComponent as RightArrow} from '../../../assets/icons/chevron-right.svg'
import {useConfigContext} from '../../../_context/config.context'
import useStyles from '../styles/quickReplyStyles'
import formatter from "../../../_helpers/formatter";

const QuickReply = React.forwardRef(({ quickReplies, toggleOpen, sendMessage }, ref) => {
  const {config} = useConfigContext()
  const {QR_LIMIT, USE_DYNAMIC_QR_LENGTH, QR_SHOW_ALL} = config
  const classes = useStyles(config);
  const qrDivRef = useRef(null);
  const [currentScrollPos, setCurrentScrollPos] = useState(0)
  const [maxScroll, setMaxScroll] = useState(0)
  const QR_MAX =
    USE_DYNAMIC_QR_LENGTH && (quickReplies.length - QR_LIMIT === 1 ) ? quickReplies.length : QR_LIMIT
    // console.log('length: ', quick);
  const handleScroll = (e, direction, ignore) => {
    if(ignore) return //if its disabled, don't scroll
    if(direction === 'left') {
      scrollLeft(qrDivRef.current, -200, 500);
    } else {
      scrollLeft(qrDivRef.current, 200, 500);
    }
  }

  const handleMouseScroll = e => {
    setCurrentScrollPos(qrDivRef.current.scrollLeft)
  }

  function scrollLeft(element, change, duration) {
    let start = element.scrollLeft,
        currentTime = 0,
        increment = 20;

    // Function to animate scroll
    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d/2;
    	if (t < 1) return c/2*t*t + b;
    	t--;
    	return -c/2 * (t*(t-2) - 1) + b;
    };

    const animateScroll = function(){
        currentTime += increment;
        let val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollLeft = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
        setCurrentScrollPos(val)
    };
    animateScroll();
}

useEffect(() => {
  // add listener for auto scroll trigger by mouse
  const qrDiv = qrDivRef.current
  setMaxScroll(qrDivRef.current.scrollWidth - qrDivRef.current.clientWidth)
  qrDiv.addEventListener("scroll", handleMouseScroll);

  return () => {
    qrDiv.removeEventListener("scroll", handleMouseScroll);
  }
}, [qrDivRef, quickReplies])

  //Check for the width of the scroll component
  let hideLeft = currentScrollPos <= 0
  let hideRight = currentScrollPos >= maxScroll
  const hideBoth = hideLeft && hideRight

  const qR = QR_SHOW_ALL ? quickReplies : quickReplies.slice(0, QR_MAX)

  return (
    <div className={classes.mainWrapper} ref={ref}>
      <LeftArrow 
        data-testid='qr-scroll-left-btn'
        className={clsx(
          hideLeft && 'disabled',
          (hideBoth || QR_SHOW_ALL) && 'hide',
          // "fas fa-chevron-left",
          classes.scrollArrows,
          'left'
        )}
        onClick={e => handleScroll(e, 'left', currentScrollPos <= 0)}
      />
      <div className={clsx(classes.qrContainer, QR_SHOW_ALL && 'showAll')} ref={qrDivRef} data-testid='qr-container'>
        {
          qR.map((qr, i) =>
          <div
            data-testid='qr-item'
            className={clsx(
              classes.qrOption,
              i === 0 && 'first',  //set margin left auto for flex item if first item
              i === quickReplies.length - 1 && quickReplies.length <= QR_MAX && 'last' //set margin right auto for flex item if last item
            )}
            key={i}
            onClick={() => sendMessage(qr)}
          >
            {formatter(qr.text)}
          </div>
        )}
        { //show 'more' button if there are more than 4 options
          quickReplies.length > QR_MAX &&
          !QR_SHOW_ALL &&
          <div className={clsx(classes.qrOption, 'last')} onClick={toggleOpen} id='toggle-backdrop' data-testid='qr-more-btn'> More </div>
        }
      </div>
      <RightArrow 
        data-testid='qr-scroll-right-btn'
        className={clsx(
          hideRight && 'disabled',
          (hideBoth || QR_SHOW_ALL) && 'hide',
          // "fas fa-chevron-right",
          classes.scrollArrows,
          'right'
        )}
        onClick={e => handleScroll(e, 'right', currentScrollPos >= maxScroll)}
      />
    </div>
  );
})

export default QuickReply;
