import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    animation: '$fade-in 0.4s ease-in-out',
    '&.active-out': {
      animation: '$scale-out 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both'
    }
  },
  '@keyframes fade-in': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    },
  },
  '@keyframes scale-out': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(0)',
      opacity: 0
    }
  },
  disconnectedDialog: {
    fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    textAlign: 'center',
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: 25,
    padding: 20,
    fontSize: 14,
    '& .dialogIcon': {
      fill: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
      height: 60,
      width: 60,
      display: 'block',
      margin: '30px auto',
      '-webkit-animation': '$flickerAnimation 1s',
      '-moz-animation': '$flickerAnimation 1s',
      '-o-animation': '$flickerAnimation 1s',
      'animation': '$flickerAnimation 1s',
    },
    '& .dialogTitle': {
      display: 'block',
      fontWeight: 'bold',
      fontSize: '1.17em',
      margin: '16px 0px',
      fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
    },
    '& .actionButton': {
      width: '95%',
      padding: '6px 20px',
      backgroundColor: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
      color: 'white',
      borderRadius: 25,
      fontSize: 14,
      fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
      textTransform: 'none',
      margin: '30px 0 10px 0',
      transition: '0.1s ease-in',
      '&:hover': {
        transform: 'translateY(-4px) scale(1.03)',
        boxShadow: '1px 6px 5px 1px #c1c1c1'
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    },
  },
}));
