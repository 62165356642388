import { makeStyles } from '@material-ui/core';

const backdropIndex = 1999

export default makeStyles(theme => ({
  backdrop: {
    zIndex: backdropIndex,
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
  },
  divider: {
    marginTop: 50,
    width: 1
  },
  touContainer: {
    padding: ({WIDE_MODE}) => WIDE_MODE ? '20px 56px' : '20px 28px',
    overflowX: 'hidden',
    textAlign: 'left',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 28px !important',
      borderRadius: 0
    },
  },
  backdropContent: {
    overflowX: 'scroll',
    boxSizing: 'border-box',
    position: 'absolute',
    bottom: 0,
    height: '100%',
    width: ({WIDE_MODE}) => WIDE_MODE ? '100%' : '400px',
    backgroundColor: 'white',
    borderRadius: ({WIDE_MODE}) => WIDE_MODE ? 28 : 8,
    textAlign: 'center',
    zIndex: backdropIndex + 1,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      borderRadius: '0 !important'
    },
  },
  headerIcon: {
    margin: '20px 0 15px 0',
    fontSize: 50,
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR
  },
  contentGrid: {
    margin: 0,
    position: 'absolute',
    padding: ' 0px 50px',
    top: '50%',
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)'
  },
  formHeader: {
    color: ({BACKGROUND_COLOR}) => BACKGROUND_COLOR,
    '& .emphasized-text': {
      color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    },
    '& .light-text': {
      fontWeight: 'normal',
    }
  },
  actionButton: {
    padding: '5px 20px',
    cursor: 'pointer',
    marginTop: 8,
    transition: '0.1s ease-in',
    backgroundColor: 'transparent',
    border: ({GLOBAL_COLOR}) => `1px solid ${GLOBAL_COLOR}`,
    borderRadius: 20,
    fontSize: 13,
    fontWeight: 700,
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    width: ({WIDE_MODE}) => WIDE_MODE ? '30%' : 'calc(100% - 20px)',
    minWidth: ({WIDE_MODE}) => WIDE_MODE ? 330 : 0,
    '&.secondary': {
      margin: '14px auto',
      color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
      textTransform: 'none',
      fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
      fontWeight: 600
    },
    '&.touBtn': {
      width: ({WIDE_MODE}) => WIDE_MODE ? '90%' : 'calc(100% - 20px)',
    },
    '&:hover': {
      border: ({BUTTON}) => `1px solid ${BUTTON.hoverState.borderColor}`,
      backgroundColor: ({BUTTON}) => BUTTON.hoverState.backgroundColor,
      color: ({BUTTON}) => BUTTON.hoverState.color,
    },
    '&:active': {
      transform: 'translateY(1px)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px) !important',
      minWidth: '0px !important'
    },
  },
  textField: {
    margin: 10,
    width: ({WIDE_MODE}) => WIDE_MODE ? '30%' : 'calc(100% - 20px)',
    minWidth: ({WIDE_MODE}) => WIDE_MODE ? 330 : 0,
    fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
    '& label': {
      fontSize: 13,
    },
    '& label.Mui-focused': {
      color: ({GLOBAL_COLOR}) =>GLOBAL_COLOR,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: ({GLOBAL_COLOR}) =>GLOBAL_COLOR,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ({GLOBAL_COLOR}) =>GLOBAL_COLOR,
    },
    '& .MuiFormHelperText-root': {
      color: 'black',
      textAlign: 'center',
      fontSize: 11,
    },
    '&:hover': {
      borderBottomColor: ({GLOBAL_COLOR}) =>GLOBAL_COLOR,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px) !important',
      minWidth: '0px !important'
    },
  },
}));
