import React, {useState, useEffect} from 'react';
import {Button} from '@material-ui/core'
import listFilter from '../../../_helpers/listFilter'
import Sanitize from 'sanitize-html'
import useStyles from '../styles/buttonMessageStyles'
import formatter, {formatterString} from "../../../_helpers/formatter";
import {webShare} from "../../../_helpers/share";

export default function ButtonMessage({ data, sendMessage, config, webSocket }) {
  const [canShare, setCanShare] = useState(false)
  const { text, buttons, shareData } = data
  const showShareBtn = canShare && shareData
  const classes = useStyles({...config, showShareBtn});
  const allowedTags = Sanitize.defaults.allowedTags.concat([ 'img', 'h1', 'h2', 'sup' ])
  const allowedSchemes = Sanitize.defaults.allowedTags.concat([ 'https', 'ftp', 'mailto', 'tel' ])
  const triggerShare = () => {
    webShare(shareData);
  }

  const handleButtonClick = btnInfo => {
    if (btnInfo.type === 'postback') {
      btnInfo.text = btnInfo.title
      sendMessage(btnInfo)
    } else if (btnInfo.type === 'url' || btnInfo.type === 'web_url') {
      if(btnInfo.url.includes('tel:'))
        window.location = btnInfo.url
      else
        window.open(btnInfo.url, '_blank');
    }
  };
  useEffect(() => {
    if(navigator.share && !canShare) setCanShare(true)
  }, [setCanShare, canShare])

  // Use text container from Text message
  const textContainer = ({children}) => {
    const isList = (children?.type === 'ul') || (Array.isArray(children) && children.findIndex(item => item?.type === 'ul') !== -1)
    const formattedHTMLString = isList ? children : formatterString(children);
    const sanitizedText = !isList && Sanitize(formattedHTMLString, {
      allowedTags, allowedSchemes
    });
    return (
      <div className={classes.buttonHeader}>
        {
          isList
          ?
          children
          :
          <div dangerouslySetInnerHTML={{__html: formattedHTMLString}} />
        }
      </div>
    )
  }

  return (
    <div className={classes.root} data-testid='button-message'>
      {listFilter(text, textContainer, classes)}
      <div className={classes.optionsContainer}>
        {buttons.map((btn, i) =>
          <Button
            disabled={!webSocket}
            key={i}
            className={classes.options}
            onClick={() => handleButtonClick(btn)}
          >
          {formatter(btn.title)}
        </Button>
        )}
      </div>
      {showShareBtn
         &&
          <Button className='shareButton' onClick={triggerShare}>
            <i className="fas fa-share" />
          </Button>
      }
    </div>
  );
}
