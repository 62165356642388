import React from 'react';
import Grid from '@material-ui/core/Grid';
import MessageList from '../elements/messageList'
import Header from '../elements/header'
import useStyles from '../styles/chatActiveScreenStyles'
import {Backdrop, Button, Fade, Modal} from "@material-ui/core";
import {ReactComponent as DialogIcon} from "../../../assets/icons/alert.svg";
import {useConfigContext} from "../../../_context/config.context";

export default function ChatActiveScreen({
  windowSize,
  handleEndChatBtn,
  chatBottomRef,
  messages,
  sendMessage,
  loading,
  focus,
  notificationVisible,
  setNotificationVisible,
  disconnectedDialogOpen,
  restartSocket,
  toggleOpenState
}) {
  const {config} = useConfigContext()
  const {
    SOCKET_DIALOG
  } = config;

  const classes = useStyles({ ...config });
  return (
    <Grid item xs={12} container className={classes.root}>
      <Modal
        open={disconnectedDialogOpen}
        aria-labelledby="notify-user-socket-state"
        aria-describedby="notify-user-socket-state"
        disablePortal={true}
        disableEnforceFocus={true}
        disableScrollLock={true}
      >
        <Fade in={disconnectedDialogOpen} timeout={600}>
          <Backdrop open={true}>
            <div className={classes.disconnectedDialog}>
              <DialogIcon className='dialogIcon' />
              <div>
                <div className='dialogTitle'>{SOCKET_DIALOG.title}</div>
                {SOCKET_DIALOG.subTitle}
              </div>
              <Button onClick={restartSocket} className='actionButton'>{SOCKET_DIALOG.buttonText}</Button>
            </div>
          </Backdrop>
        </Fade>
      </Modal>
      <Header
        handleEndChatBtn={handleEndChatBtn}
        sendMessage={sendMessage}
        toggleOpenState={toggleOpenState}
      />
      <MessageList
        webSocket
        focus={focus}
        loading={loading}
        chatBottomRef={chatBottomRef}
        messages={messages}
        windowSize={windowSize}
        sendMessage={sendMessage}
        notificationVisible={notificationVisible}
        setNotificationVisible={setNotificationVisible}
      />
    </Grid>
  );
}
