import React from 'react';
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid';
import useStyles from '../styles/welcomeScreenStyles'
import {useConfigContext} from '../../../_context/config.context'

export default function WelcomeScreen({ windowSize, unMountClass, toggleOpenState, sendMessage, socketReady}) {
  const {config} = useConfigContext()
  const {text, secondaryText, payload, active} = config.WELCOME_SCREEN_SETTINGS.actionButton
  const {welcomeTitle, welcomeMessageMain, welcomeMessageSecondary} = config.WELCOME_SCREEN_SETTINGS
  const classes = useStyles({...windowSize, ...config})
  const triggerActionButton = () => {
    const data = { text, payload }
    sendMessage(data)
  }

  return (
    <Grid item container className={clsx(classes.root, unMountClass)}>
      <Grid item xs={12} container>
        <Grid item xs={12} className={classes.description}>
          <div className={classes.header}>
            {welcomeTitle}
          </div>
          <p>
             {welcomeMessageMain} <br /><br />
             {welcomeMessageSecondary}
          </p>
        </Grid>
      </Grid>
      {
        socketReady
        &&
        active
        &&
        <Grid item xs={12}>
          <div className={clsx(classes.card, 'start')} onClick={triggerActionButton}>
            <div className={classes.cardHeader}>{text}</div>
            <p>{secondaryText}</p>
          </div>
        </Grid>
      }
    </Grid>
  );
}
