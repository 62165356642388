import React from 'react'
import formatter from "./formatter";

export default function ListFilter(data, Parent, classes){
  let firstListIndex = null
  let lastListIndex = null
  let newLineSplit = data.split('\n')
  let listItems = []
  newLineSplit.forEach((phrase, i) => {
    if (phrase.substring(0,2) === '- ') {
      if (!firstListIndex || firstListIndex < 0) {
        firstListIndex = i
      }
      if (phrase.substring(0,2) === '- ' && (newLineSplit[i + 1]?.substring(0,2) !== '- ' || !newLineSplit[i + 1])) {
        lastListIndex = i
        listItems.push(
          <ul className={classes.list}>
            {newLineSplit.slice(firstListIndex, lastListIndex + 1).map((item, idx) => <li key={idx}>{formatter(item.replace('- ', ''))}</li>)}
          </ul>)
        firstListIndex = -1
      }
    } 
    else {
      listItems.push(phrase)
      if (listItems.length) listItems.push('\n')
    }
  })
  if (firstListIndex) return (
    <Parent>
      {listItems.map((item) => item)}
    </Parent>
  )
  return <Parent>{data}</Parent>
}
