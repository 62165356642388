import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    color: 'black',
    position: 'relative',
    width: ({WIDE_MODE}) => WIDE_MODE ? '98%' : '320px',
    textAlign: 'center',
    margin: '0 auto',
    zIndex: 1
  },
  arrowButtons: {
    position: 'absolute',
    top: '55%',
    padding: 12,
    borderRadius: '50%',
    width: 50,
    border: 'none',
    cursor: 'pointer',
    fill: 'white',
    backgroundColor: '#10111785',
    zIndex: 1901,
    left: -30,
    '& svg': {
      height: 22,
      width: 22
    },
    '&.rightArrow': {
      right: -30,
      left: 'auto'
    },
    '&.hide': {
      display: 'none'
    },
    '&:focus': {
        outline: 'none'
    }
  },
  externalContainer: {
    padding: '50px 6px 10px',
    '&.noImage': {
      padding: '20px 6px 10px',
    },
    '& .shareButton': {
      position: 'absolute',
      borderRadius: '50%',
      minWidth: 0,
      width: 30,
      height: 30,
      backgroundColor: ({SHARE_BUTTON_BACKGROUND_COLOR}) => SHARE_BUTTON_BACKGROUND_COLOR,
      color: ({SHARE_ICON_COLOR}) => SHARE_ICON_COLOR,
      top: '50%',
      right: -40,
      "-ms-transform": 'translateY(-50%)',
      transform: 'translateY(-50%)',
      '&:active': {
        transform: 'translateY(-48%)',
      }
    }
  },
  internalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 240,
    minHeight: 200,
    backgroundColor: 'white',
    boxShadow: '1px 3px 7px 0px #d4d4d4',
    borderRadius: 14,
    paddingTop: 120,
    color: 'black',
    transition: '0.3s ease-in-out',
    transform: 'scale(0.9)',
    '&.active': {
      transform: 'scale(1)',
      [theme.breakpoints.up('md')]: {
        transform: ({WIDE_MODE}) => WIDE_MODE ? 'scale(0.9)' : 'scale(1)'
      }
    },
    '& .-image': {
      position: 'absolute',
      top: -33,
      left: 10,
      width: 222,
      boxShadow: '0px 4px 8px 0px #717171',
      height: 150,
      borderRadius: 14,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center'
    },
    '&.noImage': {
      paddingTop: 0
    },
    '&.noContent': {
      minHeight: 120
    }
  },
  detailsContainer: {
    padding: 10
  },
  detailsContainerTitle: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    maxWidth: '75ch',
    fontSize: 18,
    fontWeight: 'bold',
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    margin: 'revert',
    padding: 'revert',
    lineHeight: '24px'
  },
  detailsContainerTitleSubTitle: {
    fontSize: 13,
    fontWeight: 300,
    whiteSpace: 'pre-line'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 10
  },
  actionButton: {
    textTransform: 'none',
    fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
    lineHeight: 1.3,
    padding: '5px 20px',
    margin: '5px 0px',
    transition: '0.1s ease-in',
    border: ({GLOBAL_COLOR}) => `1px solid ${GLOBAL_COLOR}`,
    backgroundColor: 'transparent',
    borderRadius: 20,
    fontSize: 13,
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    width: '80%',
    '& .MuiButtonBase-root': {
      fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
      margin: '5px 0px',
    },
    '&:hover': {
      border: ({BUTTON}) => `1px solid ${BUTTON.hoverState.borderColor}`,
      backgroundColor: ({BUTTON}) => BUTTON.hoverState.backgroundColor,
      color: ({BUTTON}) => BUTTON.hoverState.color,
    },
    '&:active': {
      transform: 'translateY(1px)'
    },
    '&:disabled': {
      border: '1px solid rgba(0, 0, 0, 0.26)'
    }
  }
}))
