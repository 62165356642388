import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    borderRadius: 6,
    minHeight: ({IMG_TEMPLATE_SHOW_FULL}) => IMG_TEMPLATE_SHOW_FULL ? 0 : 150,
    textAlign: 'left',
    overflow: 'hidden',
    maxWidth: ({IMG_TEMPLATE_SHOW_FULL, WIDE_MODE, imgSize, windowSize}) =>
      IMG_TEMPLATE_SHOW_FULL ?
      WIDE_MODE ?
      imgSize?.naturalWidth //if IMG_TEMPLATE_SHOW_FULL is true and WIDE_MODE is off, set larger maxwidth
      :
      380
      :
      150, // else if IMG_TEMPLATE_SHOW_FULL is false, show normal card
    // maxHeight: ({ imgSize, windowSize}) =>imgSize?.naturalHeight > windowSize?.height * 0.6 ? windowSize?.height* 0.6 : imgSize?.naturalHeight,
    padding: ({IMG_TEMPLATE_SHOW_FULL}) => IMG_TEMPLATE_SHOW_FULL ? 0 : '22px 25px 12px 25px',
    boxShadow: '1px 3px 7px 0px #d4d4d4',
    margin: ({IMG_TEMPLATE_SHOW_FULL}) => !IMG_TEMPLATE_SHOW_FULL ? '12px auto 24px auto' : '12px 0 24px 0',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    '& .-image': {
      margin: '-22px -25px 2px -25px',
      height: 160,
      position: 'relative',
      backgroundImage: ({ url }) => `url(${url ? url : 'https://image.freepik.com/free-photo/big-city-skyline-with-urban-skyscrapers-sunset-background_1127-3601.jpg'})`,
      backgroundPosition: 'center !important',
      backgroundSize: 'cover !important',
      backgroundRepeat: 'no-repeat !important'
    },
    '& img': {
      cursor: 'pointer',
      width: ({imgSize, WIDE_MODE}) => !WIDE_MODE ? 350 : imgSize?.naturalWidth,
      height: ({imgSize, WIDE_MODE}) => !WIDE_MODE ? 'auto' : imgSize?.naturalHeight,
      display: 'block'
    },
    [theme.breakpoints.down('xs')]: {
      overflow: 'auto',
      maxHeight: ({ imgSize, windowSize}) => imgSize?.naturalHeight > windowSize?.height * 0.5 ? windowSize?.height* 0.5 : imgSize?.naturalHeight,
      maxWidth: ({IMG_TEMPLATE_SHOW_FULL, WIDE_MODE, imgSize, windowSize}) =>
        IMG_TEMPLATE_SHOW_FULL ?
        imgSize?.naturalWidth > windowSize?.width * 0.85 ? windowSize?.width* 0.85 : imgSize?.naturalWidth//if IMG_TEMPLATE_SHOW_FULL is true and WIDE_MODE is off, set larger maxwidth
        :
        150, // else if IMG_TEMPLATE_SHOW_FULL is false, show normal card
    }
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: 10,
  },
  options: {
    textTransform: 'none',
    fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
    lineHeight: 1.3,
    padding: '5px 20px',
    margin: '5px auto',
    transition: '0.1s ease-in',
    border: ({GLOBAL_COLOR}) => `1px solid ${GLOBAL_COLOR}`,
    backgroundColor: 'transparent',
    borderRadius: 20,
    fontSize: 13,
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    width: '80%',
    '&:hover': {
      border: ({BUTTON}) => `1px solid ${BUTTON.hoverState.borderColor}`,
      backgroundColor: ({BUTTON}) => BUTTON.hoverState.backgroundColor,
      color: ({BUTTON}) => BUTTON.hoverState.color,
    },
    '&:active': {
      transform: 'translateY(1px)'
    },
    '&:disabled': {
      border: '1px solid rgba(0, 0, 0, 0.26)'
    }
  }
}));
