import React from 'react';
import clsx from 'clsx'
import { Grid, Button } from '@material-ui/core';
import NavLogo from '../../assets/images/nav_logo.png'
import {useConfigContext} from '../../_context/config.context'
import useStyles from './styles'
import formatter from "../../_helpers/formatter";

export default function SideBar({handleEndChatBtn, sendMessage, webSocket, sessionEndedByUser}) {
  const {config} = useConfigContext()
  const {NAVIGATION_ITEMS, SIDEBAR, ENABLE_FEEDBACK} = config
  const {url} = SIDEBAR.logo
  const classes = useStyles({ ...config })

  const triggerPostBack = item => {
    sendMessage({
      title: item.label,
      payload: item.payload,
      text: item.label
    })
  }

  const handleLogoClick = () => {
    if(url) window.open(url, '_blank')
  }

  return (
    <Grid item md={3} className={classes.sideBarContainer} container>
      <Grid item container className={classes.navItemsContainer} data-testid='sidebar-container'>
        <div className='item logo'>
          <img
            src={NavLogo}
            alt='logo'
            className={clsx(classes.navLogo, url && 'href')}
            onClick={handleLogoClick}
          />
        </div>
        {
          NAVIGATION_ITEMS.map((item, i) =>
            <Button key={i} className='item navLink' onClick={() => triggerPostBack(item)} disabled={!webSocket || sessionEndedByUser}>
              {formatter(item.label)}
            </Button>
          )
        }
      </Grid>
      {
        SIDEBAR.enableLogoBottom
        &&
        <div className='item logo'>
        <img
          src={require("../../assets/images/nav_logo_bottom.png")}
          alt='logo'
          className={clsx(classes.navLogoBottom, url && 'href')}
          onClick={handleLogoClick}
        />
      </div>
      }
      {
        SIDEBAR.enableEndChatButton
        &&
        <Button className={classes.endButton} onClick={() => handleEndChatBtn(ENABLE_FEEDBACK)} disabled={!webSocket} data-testid='sidebar-endchat-button'>
          Logout
          <i className="fas fa-sign-out-alt"></i>
        </Button>
      }
    </Grid>
  );
}
