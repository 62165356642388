import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  
    
  root: {
    flexGrow: 1,
    borderRadius: 6,
    minHeight: ({IMG_TEMPLATE_SHOW_FULL}) => IMG_TEMPLATE_SHOW_FULL ? 0 : 150,
    textAlign: 'left',
    overflow: 'hidden',
    maxWidth: 150,
    padding: '22px 25px 12px 25px',
    boxShadow: '1px 3px 7px 0px #d4d4d4',
    margin: '12px auto 24px auto',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      overflow: 'auto',
      maxHeight: ({ imgSize, windowSize}) => imgSize?.naturalHeight > windowSize?.height * 0.5 ? windowSize?.height* 0.5 : imgSize?.naturalHeight,
      maxWidth:  150,
    },
    '& img': {
      padding: 10,
      width: 56,
      height: 63
    }
  },
  internalText: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 5,
    justifyContent: 'center',
    flexDirection: 'row-reverse',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: 10,
  },
  options: {
    textTransform: 'none',
    fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
    lineHeight: 1.3,
    padding: '5px 20px',
    margin: '5px auto',
    transition: '0.1s ease-in',
    border: ({GLOBAL_COLOR}) => `1px solid ${GLOBAL_COLOR}`,
    backgroundColor: 'transparent',
    borderRadius: 20,
    fontSize: 13,
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    width: '80%',
    '&:hover': {
      border: ({BUTTON}) => `1px solid ${BUTTON.hoverState.borderColor}`,
      backgroundColor: ({BUTTON}) => BUTTON.hoverState.backgroundColor,
      color: ({BUTTON}) => BUTTON.hoverState.color,
    },
    '&:active': {
      transform: 'translateY(1px)'
    },
    '&:disabled': {
      border: '1px solid rgba(0, 0, 0, 0.26)'
    }
  }
}));
