import React, {useState} from 'react';
import FileIconGenerator from '../../../_helpers/fileIconGenerator'
import {Button} from '@material-ui/core'
import useStyles from '../styles/fileMessageStyles'

export default function FileMessage({windowSize, data, sendMessage, config, webSocket }) {
  const {
    url = null,
  } = data
  const classes = useStyles({ url, windowSize, ...config });

  const handleButtonClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div className={classes.root} data-testid='file-message'>
        <div className={classes.internalText}>
          <img src={FileIconGenerator(url.split('?')[0].substring(url.split('?')[0].lastIndexOf(".") + 1))} alt='fileIcon' />
        </div>
        <div className={classes.optionsContainer}>
          <Button
            className={classes.options}
            onClick={handleButtonClick}
            disabled={!webSocket}
            >
            View File
          </Button>
        </div>
    </div>
  );
}
