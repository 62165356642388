import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    height:
      ({ height, WIDE_MODE, WELCOME_SCREEN_SETTINGS }) =>
        `${ WELCOME_SCREEN_SETTINGS.actionButton.active
          ? height - 40 //height if welcome button is active
          : WIDE_MODE
          ? height - 98 //height if welcome button is inactivce and WIDE_MODE is enabled
          : height * 0.75 - 50}px`, //default height without WIDE_MODE
    animation: '$fade-in 0.5s ease-in-out',
    backgroundColor: ({WELCOME_COLOR}) => WELCOME_COLOR,
    borderRadius: ({WIDE_MODE}) => WIDE_MODE ? '28px 0 0 28px' : 8,
    padding: '10px 40px',
    color: 'white',
    '&.welcome-out': {
      animation: '$scale-out 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both'
    },
    [theme.breakpoints.down('xs')]: {
      top: 0,
      height: ({WELCOME_SCREEN_SETTINGS, height}) =>
        `${WELCOME_SCREEN_SETTINGS.actionButton.active ? height : height - 56}px !important`,
      borderRadius: '0px !important'
    }
  },
  header: {
    marginTop: 70,
    marginBottom: 16,
    fontSize: 28,
    display: 'block',
    fontWeight: 'bold',
    fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
  },
  description: {
    whiteSpace: 'pre-wrap'
  },
  minimizeIcon: {
    float: 'right',
    cursor: 'pointer',
    paddingTop: 24,
    fontSize: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  '@keyframes fade-in': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    },
  },
  '@keyframes scale-out': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(0)',
      opacity: 0
    }
  },
  card: {
   textAlign: 'center',
   border: '1px solid white',
   borderRadius: 12,
   transition: '0.2s ease-in-out',
   cursor: 'pointer',
   fontSize: 12,
   marginTop: 20,
   width: 'calc(100% - 80px)',
   '&:hover': {
     backgroundColor: '#8a8a8a5c',
     transform: 'scale(1.02)'
   },
   '&.start': {
     position: 'absolute',
     bottom: 30
   }
 },
 cardHeader: {
  fontSize: 24,
  display: 'block',
  fontWeight: 'bold',
  margin: '13px 0px',
  fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
 }
}));
