import * as React from 'react';
import reactStringReplace from 'react-string-replace';

const formatter = (textContent) => {
  if (!textContent) {
    return textContent;
  }

  let formattedText = textContent;
// <span>
  const formatterArr = [
    { // _
      matcher: /\b_([^\s].*?|\S*?[^\s])_\b/gmi,
      replacer: (match) => <span style={{ fontStyle: 'italic', }}>{match}</span>,
    },
    { // *
      matcher: /\B\*([^\s].*?|\S*?[^\s])\*\B/gmi,
      replacer: (match) => <span style={{ fontWeight: 'bold' }}>{match}</span>,
    },
    { // ~
      matcher: /\b~([^\s].*?|\S*?[^\s])~\b/gmi,
      replacer: (match) => <span style={{ textDecoration: 'line-through' }}>{match}</span>,
    },
    { // ^
      matcher: /\B\^([^\s].*?|\S*?[^\s])\^\B/gmi,
      replacer: (match) => <span style={{ position: 'relative', top: '-0.5em', fontSize: '80%' }}>{match}</span>,
    }
  ];

  // formatterHelper.forEach(
  //   formatterItem => {
  //     if (Array.isArray(formattedText)) {
  //       // @ts-ignore
  //       formattedText = formattedText.map(text => {
  //         return text.replace ? text.replace(formatterItem, '$1$1$2$3$3') : text;
  //       });
  //     } else {
  //       formattedText = formattedText.replace(formatterItem, '$1$1$2$3$3');
  //     }
  //   }
  // );
  // console.log('start', { formattedText })

  formatterArr.forEach(
    formatterItem => {
      formattedText = reactStringReplace(
        formattedText, formatterItem.matcher, formatterItem.replacer
      );
    }
  );

  return formattedText;
};

export default formatter;

export const formatterString = (textContent) => {
  if (!textContent) {
    return textContent;
  }

  let formattedText = textContent;
// <span>
  const formatterArr = [
    { // _
      matcher: /\b_([^\s].*?|\S*?[^\s])_\b/gmi,
      replacer: `<i>$1</i>`,
    },
    { // *
      matcher: /\B\*([^\s].*?|\S*?[^\s])\*\B/gmi,
      replacer: `<b>$1</b>`,
    },
    { // ~
      matcher: /\b~([^\s].*?|\S*?[^\s])~\b/gmi,
      replacer: `<strike>$1</strike>`,
    },
    { // ^
      matcher: /\B\^([^\s].*?|\S*?[^\s])\^\B/gmi,
      replacer: `<sup>$1</sup>`,
    }
  ];

  // formatterHelper.forEach(
  //   formatterItem => {
  //     if (Array.isArray(formattedText)) {
  //       // @ts-ignore
  //       formattedText = formattedText.map(text => {
  //         return text.replace ? text.replace(formatterItem, '$1$1$2$3$3') : text;
  //       });
  //     } else {
  //       formattedText = formattedText.replace(formatterItem, '$1$1$2$3$3');
  //     }
  //   }
  // );
  // console.log('start', { formattedText })

  formatterArr.forEach(
    formatterItem => {
      formattedText = formattedText.replace(formatterItem.matcher, formatterItem.replacer);
      return formattedText
    }
  );

  return formattedText;
};